import CartItem from "@/components/atoms/CartItem/CartItem";

import { ICartItem } from "@/lib/centra/formatters/types";
import clsx from "clsx";
import styles from "./cart-items.module.css";

interface ICartItemsProps {
  items: ICartItem[] | null;
  isFinalized?: boolean;
  clean?: boolean;
}

const CartItems = ({ items, isFinalized, clean }: ICartItemsProps) => {
  return (
    <div className={clsx(styles.container, clean && styles.clean)}>
      {items?.map((item, index) => {
        return (
          <CartItem
            item={item}
            key={`${item.id}-${
              item.customizationItems
                ? item.customizationItems.toString()
                : index
            }`}
            isFinalized={isFinalized}
          />
        );
      })}
    </div>
  );
};

export default CartItems;
